import React from "react"
import { useGallery } from "../hooks/usegallery"
import Img from "gatsby-image"

export default props => {
  const gallery = useGallery()
  return (
    <div className="container">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
        <span className="d-block small font-weight-bold text-cap mb-2"></span>
        <h2> Gallery</h2>
      </div>

      <div className="row mx-n2">
        {gallery.map((item, key) => (
          <div className="col-lg-4 col-sm-6 px-2 mb-3" key={key}>
            {/* <div
              className="h-380rem"
              style={{
                backgroundImage: `url(${item.image.asset.fluid.src})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div> */}
            <a
              href={item.image.asset.url}
              target="blank"
              rel="noopener noreferrer"
            >
              <Img fluid={item.image.asset.fluid} className="h-380rem" />
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}
