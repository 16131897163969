import React from "react"
import Layout from "../components/layout/layout"
import Feature from "../components/fullfeature"
import Gallery from "../components/gallery"
import Hero from "../components/hero"
import { graphql } from 'gatsby'
import { Helmet } from "react-helmet"

export default data => {
  const feature = {
    headline: "Bio Feature Headline",
    text: data.data.sanityPages._rawContent[0].text,
    image: data.data.sanityPages.content[0].picture.asset.fluid.src,
  }

  return (
    <Layout>
      <Helmet title={`Rajiv Satyal - ${data.data.sanityPages.title}`} />
      <Hero data={data.data.sanityPages} />
      <Feature feature={feature} />
      <Gallery />
    </Layout>
  )
}

export const query = graphql`
  query BioQuery {
    sanityPages(slug: { current: { eq: "bio" } }) {
      id
      hero_subtitle
      hero_title
      hero_video
      title
      _rawContent(resolveReferences: { maxDepth: 10 })
      content {
        ... on SanityBio {
          picture {
            asset {
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
                src
              }
            }
          }
        }
      }
      hero_bg {
        hotspot {
          x
          y
        }

        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
            src
          }
        }
      }
    }
  }
`
