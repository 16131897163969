import React from "react"
import BlockContent from "@sanity/block-content-to-react"

export default props => {
  return (
    <div className="container space-2">
      <div className="row justify-content-lg-between">
        <div className="col-lg-12 pr-10 pl-10">
          <div className="mb-0">
            <BlockContent blocks={props.feature.text} />
            {props.feature.pdf && (
              <div className="text-left">
                <a
                  className="btn btn-primary transition-3d-hover text-left mt-4 mb-1 mb-sm-0 mx-1"
                  rel="noopener noreferrer"
                  href={props.feature.pdf}
                  target="_blank"
                >
                  Download PDF
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
